
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import ContactAndCompanyNotes from '@/components/ContactAndCompanyNotes.vue'
import ContactReservations from '@/components/ContactReservations.vue'
import ContactQuotes from '@/components/ContactQuotes.vue'
import CUTabs from '@/components/CUTabs.vue'
import DetailsDueCard from '@/components/DetailsDueCard.vue'
import DetailsStatsCard from '@/components/DetailsStatsCard.vue'
import { Tab } from '@/models/dto/Tab'
import auth from '@/store/modules/auth'
import CompanyContacts from '@/components/CompanyContacts.vue'
import CompanyQuotes from '@/components/CompanyQuotes.vue'
import CompanyReservations from '@/components/CompanyReservations.vue'
import quoteClient from '@/services/quotes'
import reservationClient from '@/services/reservation'
import { TimeSeriesRequest, TimeSeriesResult } from '@/models/dto/Time'
import { Note } from '@/models/dto/Note'
import dayjs from 'dayjs'
import ContactAndCompanyDefaults from './ContactAndCompanyDefaults.vue'
import { CustomerAccount } from '@/models/dto/CustomerAccount'
import { Contact } from '@/models/Contact'
import { ACCESS_SETTINGS_ROLES } from '@/models/AccessSettings'

@Component({
  components: {
    CUTabs,
    DetailsDueCard,
    DetailsStatsCard,
  },
})
export default class DetailsContent extends Vue {
  @Prop({ required: false }) object: Partial<Contact> | Partial<CustomerAccount>
  @Prop({ required: true }) fullName: string
  @Prop({ required: false, default: false, type: Boolean }) isContact: boolean
  @Prop({ required: false }) companyId: number
  @Prop({ required: false }) note: Note
  @Prop({ required: false }) id: number

  quoteTimeSeriesData = null
  reservationBalanceSummary = null
  reservationTimeSeriesData = null
  startDate = ''
  endDate = ''
  loading = true

  @Watch('id', { immediate: true })
  async handleCustomerIdChange(id: number): Promise<void> {
    const now = dayjs()
    const startDatetime = now.subtract(1, 'year').format('YYYY-MM-DD')
    const endDatetime = now.format('YYYY-MM-DD')
    if (this.isContact) {
      await this.getFinancialDataForContact(startDatetime, endDatetime, id)
    }
    this.loading = false
  }

  @Watch('companyId', { immediate: true })
  async handleCompanyIdChange(companyId: number): Promise<void> {
    const now = dayjs()
    const startDatetime = now.subtract(1, 'year').format('YYYY-MM-DD')
    const endDatetime = now.format('YYYY-MM-DD')
    if (!this.isContact) {
      await this.getFinancialDataForCompany(
        startDatetime,
        endDatetime,
        companyId
      )
    }
    this.loading = false
  }

  async getFinancialDataForContact(
    startDatetime: string,
    endDatetime: string,
    customerId: number
  ): Promise<void> {
    if (!customerId) {
      return
    }
    const payload: TimeSeriesRequest = {
      startDatetime,
      endDatetime,
    }

    const quoteTimeSeriesData = await quoteClient.quoteTimeSeriesCustomerData(
      customerId,
      payload
    )
    const reservationTimeSeriesData =
      await reservationClient.reservationTimeSeriesCustomerData(
        customerId,
        payload
      )

    const reservationBalanceSummary =
      await reservationClient.reservationCustomerBalanceSummary(customerId)

    const balanceSummaryData =
      reservationBalanceSummary?.data?.customerBalanceSummaryDTO

    this.updateData(
      quoteTimeSeriesData?.data,
      balanceSummaryData,
      reservationTimeSeriesData?.data,
      startDatetime,
      endDatetime
    )
  }

  async getFinancialDataForCompany(
    startDatetime: string,
    endDatetime: string,
    companyId: number
  ): Promise<void> {
    if (!companyId) {
      return
    }
    const payload: TimeSeriesRequest = {
      startDatetime,
      endDatetime,
    }
    const quoteTimeSeriesData =
      await quoteClient.quoteTimeSeriesCustomerAccountData(companyId, payload)
    const reservationTimeSeriesData =
      await reservationClient.reservationTimeSeriesCustomerAccountData(
        companyId,
        payload
      )

    const reservationBalanceSummary =
      await reservationClient.reservationCustomerAccountBalanceSummary(
        companyId
      )
    const balanceSummaryData =
      reservationBalanceSummary?.data?.customerBalanceSummaryDTO

    this.updateData(
      quoteTimeSeriesData?.data,
      balanceSummaryData,
      reservationTimeSeriesData?.data,
      startDatetime,
      endDatetime
    )
  }

  updateData(
    quoteTimeSeriesData: TimeSeriesResult,
    reservationBalanceSummary: {
      totalBalance: number
      totalOverdueBalance: number
    },
    reservationTimeSeriesData: TimeSeriesResult,
    startDate: string,
    endDate: string
  ): void {
    this.quoteTimeSeriesData = quoteTimeSeriesData
    this.reservationBalanceSummary = reservationBalanceSummary
    this.reservationTimeSeriesData = reservationTimeSeriesData
    this.startDate = startDate
    this.endDate = endDate
  }

  filterTabsByPermissions(tabs: Tab[]): Tab[] {
    const tabsToKeep = ['Notes', 'Defaults']
    for (const role of auth.getRoles) {
      const { roleName } = role
      if (roleName === ACCESS_SETTINGS_ROLES.QUOTES) {
        tabsToKeep.push('Quotes')
      } else if (roleName === ACCESS_SETTINGS_ROLES.RESERVATIONS) {
        tabsToKeep.push('Reservations')
      } else if (roleName === ACCESS_SETTINGS_ROLES.CONTACTS) {
        tabsToKeep.push('Contacts')
      }
    }

    return tabs.filter((tab) => tabsToKeep.includes(tab.label))
  }

  get contactTabs(): Tab[] {
    const tabs = [
      {
        label: 'Notes',
        component: ContactAndCompanyNotes,
        props: {
          note: this.note,
          isContact: true,
        },
      },
      {
        label: 'Defaults',
        component: ContactAndCompanyDefaults,
        props: {
          isContact: true,
          object: this.object,
        },
      },
      {
        label: 'Quotes',
        component: ContactQuotes,
        // TODO: replace name with ID once surfaced on backend
        props: {
          name: this.fullName,
        },
      },
      {
        label: 'Reservations',
        component: ContactReservations,
        // TODO: replace name with ID once surfaced on backend
        props: {
          name: this.fullName,
        },
      },
      // POST LAUNCH
      // {
      //   label: 'Tickets',
      //   component: ContactTickets,
      //   // TODO: replace name with ID once surfaced on backend
      //   props: {
      //     name: this.fullName,
      //   },
      // },
    ]

    return this.filterTabsByPermissions(tabs)
  }

  get companyTabs(): Tab[] {
    const tabs = [
      {
        label: 'Notes',
        component: ContactAndCompanyNotes,
        props: {
          note: this.note,
          isContact: false,
        },
      },
      {
        label: 'Defaults',
        component: ContactAndCompanyDefaults,
        props: {
          isContact: false,
          object: this.object,
        },
      },
      {
        label: 'Quotes',
        component: CompanyQuotes,
        // TODO: replace name with ID once surfaced on backend
        props: {
          customerAccountName: this.fullName,
        },
      },
      {
        label: 'Reservations',
        component: CompanyReservations,
        props: {
          customerAccountName: this.fullName,
        },
      },
      // POST LAUNCH
      // {
      //   label: 'Tickets',
      //   component: CompanyTickets,
      //   props: {
      //     companyId: this.companyId,
      //   },
      // },
      {
        label: 'Contacts',
        component: CompanyContacts,
        props: {
          companyId: this.companyId,
        },
      },
    ]

    return this.filterTabsByPermissions(tabs)
  }

  tab = 0

  handleStatsDateRangeChange([startDate, endDate]: string[]): void {
    if (this.isContact) {
      this.getFinancialDataForContact(startDate, endDate, this.id)
    } else {
      this.getFinancialDataForCompany(startDate, endDate, this.companyId)
    }
  }
}
