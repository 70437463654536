
import { Prop } from 'vue-property-decorator'
import Component, { mixins } from 'vue-class-component'
import DateMixin from '@/mixins/DateMixin'
import { DataTableColumn } from '@/models/DataTableColumn'
import reservations from '@/services/reservation'
import { currencyFilter, toTitle } from '@/utils/string'
import CUCollectionTable from '@/components/CUCollectionTable.vue'
import { TableAction } from '@/models/TableAction'
import { Reservation } from '@/models/dto/Reservation'
import { RawLocation } from 'vue-router'
import CUDataTableLink from '@/components/CUDataTableLink.vue'
import ReservationsListTotalDue from '@/components/ReservationsListTotalDue.vue'
import ReservationsListDriverAssignments from '@/components/ReservationsListDriverAssignments.vue'
import ReservationsListVehicleAssignments from '@/components/ReservationsListVehicleAssignments.vue'
import { filter } from '@/utils/filter'
import { v4 } from 'uuid'
import {
  CURRENCY_COLUMN_WIDTH,
  DATE_COLUMN_WIDTH,
  NAME_COLUMN_WIDTH,
} from '@/data/tableViews'
import { TableViewParameters, TableViewResult } from '@/models/TableView'
import { AxiosResponse } from 'axios'

@Component({
  components: { CUCollectionTable },
})
export default class ContactReservations extends mixins(DateMixin) {
  @Prop({ required: false }) readonly name!: string

  filteredTableView(
    params: TableViewParameters
  ): Promise<AxiosResponse<TableViewResult<Reservation>>> {
    const filters = filter()
    const parentFilter = filters.createParent('and')
    filters.add(parentFilter, {
      column: {
        _t_id: v4(),
        value: 'customerName',
        filterAsIs: true,
        filterType: 'eq',
      },
      value: this.name,
    })
    params = { ...params, filters: filters.asQueryParams() }
    return reservations.tableView(params)
  }

  count = 0

  columns: DataTableColumn[] = [
    {
      _t_id: '97f8c23a-2408-445b-94a0-992cf31c7a18',
      text: 'Res. ID',
      value: 'managedId',
      type: 'slot',
      columnSlotComponent: CUDataTableLink,
      linkLocation: (row: Reservation): RawLocation => ({
        name: 'reservations.detail',
        params: { id: String(row.reservationId) },
      }),
      hidden: false,
      width: 110,
    },
    {
      _t_id: '7c867fa7-ec4f-42f4-9546-8b5188b6f59d',
      text: 'Res. Status',
      value: 'reservationStatus',
      computedText: (row): string => toTitle(row.reservationStatus),
      hidden: false,
      width: 120,
    },
    {
      _t_id: '19c9b30b-27df-4619-b6c0-97b35a076002',
      text: 'Pickup Date',
      value: 'pickupDate',
      computedText: (row): string =>
        this.formatLongDateLongTime(row.pickupDate, {
          showTimezone: true,
          showMeridianUpper: true,
          showDot: true,
          tz: row.firstPickupAddressTimezone,
        }) || '',
      hidden: false,
      width: DATE_COLUMN_WIDTH,
    },
    {
      _t_id: 'a33b8f4b-0f1f-49a8-b83d-b0a0be709ab5',
      text: 'Total Due',
      value: 'amount',
      columnSlotComponent: ReservationsListTotalDue,
      hidden: false,
      width: CURRENCY_COLUMN_WIDTH,
    },
    {
      _t_id: 'fd216ac9-7394-44f9-975a-6500d0eeb98b', // need to surface due date to support highlighting when overdue
      text: 'Balance',
      value: 'balance',
      computedText: (row): string => currencyFilter(row.balance),
      hidden: false,
      width: CURRENCY_COLUMN_WIDTH,
    },
    {
      _t_id: 'da2a0916-ae17-4dcb-8c3d-1726cc84c2dc',
      text: 'Driver',
      value: 'vehicleAssignments/driverAssignments',
      type: 'slot',
      columnSlotComponent: ReservationsListDriverAssignments,
      hidden: false,
      width: NAME_COLUMN_WIDTH,
    },
    {
      _t_id: '06fc72f1-ac42-4aa5-a58b-0d0f2a11a5aa',
      text: 'Vehicle',
      value: 'vehicleAssignments',
      type: 'slot',
      columnSlotComponent: ReservationsListVehicleAssignments,
      hidden: false,
      width: NAME_COLUMN_WIDTH,
    },
    {
      _t_id: 'f5c323cb-289a-4796-94b2-5c59f100d4d3',
      text: 'Last Activity',
      value: 'createdOn', //needs to be changed to updatedOn once that's surfaced
      computedText: (row): string => this.formatMediumDate(row.createdOn),
      hidden: false,
      width: DATE_COLUMN_WIDTH,
    },
  ]
}
