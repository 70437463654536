
import { EventBus } from '@/utils/eventBus'
import { Prop, Watch } from 'vue-property-decorator'
import DateMixin from '@/mixins/DateMixin'
import Component, { mixins } from 'vue-class-component'
import { Note } from '@/models/dto/Note'
import { cleanEmptyHtml } from '@/utils/string'
import auth from '@/store/modules/auth'
import dayjs from 'dayjs'

@Component({})
export default class ContactAndCompanyNotes extends mixins(DateMixin) {
  @Prop({ required: false }) readonly note!: Note
  @Prop({ required: false, default: true }) readonly isContact!: boolean

  @Watch('note', { immediate: true, deep: true })
  noteChange(value: Note): void {
    this.newNote = { ...value } || new Note()
    this.setNoteContent()
  }

  createNote = false
  newNote: Note = new Note()
  noteContent = ''
  showEditIcon = false

  get createdOnDate(): string {
    const dateFormat = auth.shouldDisplayDayLeading
      ? 'D MMM YYYY'
      : 'MMM D, YYYY'
    const date = dayjs(this.newNote.createdOn)
      .tz(auth.getUserTimeZone)
      .format(dateFormat)
    const time = this.formatShortTime(this.newNote.createdOn, {
      showMeridianUpper: true,
      showTimezone: true,
      tz: auth.getUserTimeZone,
    })

    return `${date} at ${time}`
  }

  setNoteContent(): void {
    this.noteContent =
      cleanEmptyHtml(this.newNote.html) || this.newNote.note || ''
  }

  cancelNote(): void {
    this.newNote = this.note
    this.createNote = false
    this.showEditIcon = false
  }

  handleNoteTextChange(text: string): void {
    this.newNote.note = text
  }

  handleNoteHtmlChange(html: string): void {
    this.newNote.html = html
  }

  handleSubmitNote(): void {
    this.createNote = false
    EventBus.$emit('note:create', this.newNote, this.isContact)
    this.showEditIcon = false
  }
}
