
import { Prop } from 'vue-property-decorator'
import Component, { mixins } from 'vue-class-component'
import DateMixin from '@/mixins/DateMixin'
import { DataTableColumn } from '@/models/DataTableColumn'
import quotes from '@/services/quotes'
import { currencyFilter } from '@/utils/string'
import CUCollectionTable from '@/components/CUCollectionTable.vue'
import { TableAction } from '@/models/TableAction'
import { Quote } from '@/models/dto/Quote'
import { filter } from '@/utils/filter'
import CUDataTableLink from './CUDataTableLink.vue'
import QuotesListQuoteStatus from './QuotesListQuoteStatus.vue'
import { RawLocation } from 'vue-router'
import {
  CURRENCY_COLUMN_WIDTH,
  DATE_COLUMN_WIDTH,
  NAME_COLUMN_WIDTH,
} from '@/data/tableViews'
import { Status } from '@/models/dto/Status'
import { TableViewParameters, TableViewResult } from '@/models/TableView'
import { AxiosResponse } from 'axios'
import { quoteStatusTypes } from '@/data/types'

@Component({
  components: { CUCollectionTable },
})
export default class ContactQuotes extends mixins(DateMixin) {
  @Prop({ required: false }) readonly name!: string

  count = 0

  actions: TableAction[] = [
    {
      displayText: 'Delete',
      key: 'delete',
      icon: 'delete',
    },
    {
      displayText: 'Duplicate Quote',
      key: 'duplicate-quote',
      icon: 'copy',
      isSingleSelectOnly: true,
    },
  ]

  columns: DataTableColumn[] = [
    {
      _t_id: '4fb8bce1-372b-488a-9210-fc7572f235d3',
      text: 'Quote ID',
      value: 'managedId',
      type: 'slot',
      linkLocation: (row: Quote): RawLocation => ({
        name: 'quotes.detail',
        params: { id: String(row.quoteId) },
      }),
      filterProp: 'managedId',
      columnSlotComponent: CUDataTableLink,
      hidden: false,
      required: true,
      width: 110,
    },
    {
      _t_id: '267676b8-3405-4fd5-9f68-806c10d1ce92',
      text: 'Quote Status',
      value: 'quoteStatus',
      type: 'slot',
      columnSlotComponent: QuotesListQuoteStatus,
      columnSlotProps: (row: Quote) => ({
        status: this.getQuoteStatus(row),
        expirationDate: row.expirationDate,
      }),
      hidden: false,
      width: 160,
    },
    {
      _t_id: 'de16fc84-6f54-4de7-86bd-ff54aea08dd7',
      text: 'Company',
      value: 'customerAccountName',
      type: 'text',
      hidden: false,
      width: NAME_COLUMN_WIDTH,
    },
    {
      _t_id: 'ed1896b7-300c-45c2-a0e9-64df78f8647f',
      text: 'Pickup Date',
      value: 'pickupDate',
      computedText: (row): string =>
        this.formatLongDateLongTime(row.pickupDate, {
          showTimezone: true,
          showMeridianUpper: true,
          showDot: true,
          tz: row.firstPickupAddressTimezone,
        }),
      hidden: false,
      width: DATE_COLUMN_WIDTH,
    },
    {
      _t_id: 'a704211f-2a21-4e75-9b61-dacc81f5fb48',
      text: 'Total',
      value: 'amount',
      computedText: (row): string => currencyFilter(row.amount),
      hidden: false,
      width: CURRENCY_COLUMN_WIDTH,
    },
    {
      _t_id: 'de16fc84-6f54-4de7-86bd-ff54aea08dd7',
      text: 'Created',
      value: 'createdOn',
      computedText: (row): string => this.formatMediumDate(row.createdOn),
      hidden: false,
      width: DATE_COLUMN_WIDTH,
    },
    {
      _t_id: 'e8b356bb-2077-4115-8fce-28e2d78c0b15',
      text: 'Created By',
      value: 'createdByName',
      filterProp: 'createdByName',
      hidden: false,
      width: NAME_COLUMN_WIDTH,
    },
  ]

  filteredTableView(
    params: TableViewParameters
  ): Promise<AxiosResponse<TableViewResult<Quote>>> {
    const filters = filter()
    const parentFilter = filters.createParent('and')
    filters.add(parentFilter, {
      column: {
        _t_id: 'e8b356bb-2077-4115-8fce-28e2d78c0b15',
        value: 'customerName',
        filterAsIs: true,
        filterType: 'eq',
      },
      value: this.name,
    })
    params = { ...params, filters: filters.asQueryParams() }
    return quotes.tableView(params)
  }

  getQuoteStatus(row: Quote): Status {
    const status: Status = quoteStatusTypes?.find(
      (status) => status.id === row.quoteStatusId
    )
    return status || quoteStatusTypes[0]
  }
}
