
import { Prop } from 'vue-property-decorator'
import Component, { mixins } from 'vue-class-component'
import { DataTableColumn } from '@/models/DataTableColumn'
import quotes from '@/services/quotes'
import { currencyFilter } from '@/utils/string'
import CUCollectionTable from '@/components/CUCollectionTable.vue'
import { TableAction } from '@/models/TableAction'
import sidebar from '@/store/modules/sidebar'
import { EventBus } from '@/utils/eventBus'
import { Quote } from '@/models/dto/Quote'
import { filter } from '@/utils/filter'
import CUDataTableLink from './CUDataTableLink.vue'
import QuotesListQuoteStatus from './QuotesListQuoteStatus.vue'
import { RawLocation } from 'vue-router'
import ContactSidebarDetail from './ContactSidebarDetail.vue'
import {
  NAME_COLUMN_WIDTH,
  CURRENCY_COLUMN_WIDTH,
  DATE_COLUMN_WIDTH,
} from '@/data/tableViews'
import { Status } from '@/models/dto/Status'
import { TableViewParameters, TableViewResult } from '@/models/TableView'
import { AxiosResponse } from 'axios'
import auth from '@/store/modules/auth'
import { ACCESS_SETTINGS_ROLES } from '@/models/AccessSettings'
import DateMixin from '@/mixins/DateMixin'
import { quoteStatusTypes } from '@/data/types'

@Component({
  components: { CUCollectionTable },
})
export default class CompanyQuotes extends mixins(DateMixin) {
  @Prop({ required: false }) readonly customerAccountName!: string

  count = 0

  get canViewContacts(): boolean {
    return auth.getUserRoleNames.includes(ACCESS_SETTINGS_ROLES.CONTACTS)
  }

  actions: TableAction[] = [
    {
      displayText: 'Delete',
      key: 'delete',
      icon: 'delete',
    },
    {
      displayText: 'Duplicate Quote',
      key: 'duplicate-quote',
      icon: 'copy',
      isSingleSelectOnly: true,
    },
  ]

  columns: DataTableColumn[] = [
    {
      _t_id: 'd86ebeb3-de83-47af-acdf-8060f4d0b8e8',
      text: 'Quote ID',
      value: 'managedId',
      type: 'slot',
      linkLocation: (row: Quote): RawLocation => ({
        name: 'quotes.detail',
        params: { id: String(row.quoteId) },
      }),
      filterProp: 'managedId',
      columnSlotComponent: CUDataTableLink,
      hidden: false,
      required: true,
      width: 110,
    },
    {
      _t_id: 'dec709a7-056d-47a5-b78d-01bb8042d4dc',
      text: 'Quote Status',
      value: 'quoteStatus',
      type: 'slot',
      columnSlotComponent: QuotesListQuoteStatus,
      columnSlotProps: (row: Quote) => ({
        status: this.getQuoteStatus(row),
        expirationDate: row.expirationDate,
      }),
      hidden: false,
      width: 160,
    },
    {
      _t_id: 'ecede699-2a2d-4640-9106-4a489241fca2',
      text: 'Booking Contact',
      value: 'customerName',
      columnSlotComponent: this.canViewContacts ? CUDataTableLink : null,
      linkLocation: (row: Quote): RawLocation => ({
        name: 'contacts.detail',
        params: { id: String(row.customerId) },
      }),
      clickEvent: 'quotes:open-booked-by',
      filterProp: 'customerName',
      hidden: false,
      width: NAME_COLUMN_WIDTH,
    },
    {
      _t_id: '61ee8d06-231c-4a30-8eeb-a92d160f22b6',
      text: 'Pickup Date',
      value: 'pickupDate',
      computedText: (row): string =>
        this.formatLongDateLongTime(row.pickupDate, {
          showTimezone: true,
          showMeridianUpper: true,
          showDot: true,
          tz: row.firstPickupAddressTimezone,
        }),
      hidden: false,
      width: DATE_COLUMN_WIDTH,
    },
    {
      _t_id: 'cc7f69e5-6206-442a-9dc2-421bb23ef6b9',
      text: 'Total',
      value: 'amount',
      computedText: (row): string => currencyFilter(row.amount),
      hidden: false,
      width: CURRENCY_COLUMN_WIDTH,
    },
    {
      _t_id: '5b00b76a-4a79-458f-8bed-129b77de96e6',
      text: 'Created',
      value: 'createdOn',
      computedText: (row): string => this.formatMediumDate(row.createdOn),
      hidden: false,
      width: DATE_COLUMN_WIDTH,
    },
    {
      _t_id: 'c402d468-7503-4730-abf5-c9f044c53f5a',
      text: 'Created By',
      value: 'createdByName',
      filterProp: 'createdByName',
      hidden: false,
      width: NAME_COLUMN_WIDTH,
    },
  ]

  filteredTableView(
    params: TableViewParameters
  ): Promise<AxiosResponse<TableViewResult<Quote>>> {
    const filters = filter()
    const parentFilter = filters.createParent('and')
    filters.add(parentFilter, {
      column: {
        _t_id: '0c3c503c-6a5d-40f4-afb2-ae0ad8bf23e9',
        value: 'customerAccountName',
        filterAsIs: true,
        filterType: 'eq',
      },
      value: this.customerAccountName,
    })
    params = { ...params, filters: filters.asQueryParams() }
    return quotes.tableView(params)
  }

  openBookedBy(row: Quote): void {
    sidebar.push({
      component: ContactSidebarDetail,
      props: { userId: row.customerId },
      title: 'Booked By',
      fab: true,
    })
  }

  getQuoteStatus(row: Quote): Status {
    const status: Status = quoteStatusTypes?.find(
      (status) => status.id === row.quoteStatusId
    )
    return status || quoteStatusTypes[0]
  }

  mounted(): void {
    EventBus.$on('quotes:open-booked-by', this.openBookedBy)
  }

  beforeDestroy(): void {
    EventBus.$off('quotes:open-booked-by', this.openBookedBy)
  }
}
