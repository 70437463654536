
import { Customer } from '@/models/dto/Customer'
import { CustomerAccount } from '@/models/dto/CustomerAccount'
import { EventBus } from '@/utils/eventBus'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

@Component({})
export default class ContactAndCompanyDefaults extends Vue {
  @Prop({ required: false, default: true }) readonly isContact!: boolean
  @Prop({ required: true }) object!:
    | Partial<Customer>
    | Partial<CustomerAccount>

  awaitingObjectUpdate = true

  @Watch('object', { deep: true, immediate: true })
  objectUpdate(): void {
    this.awaitingObjectUpdate = false
  }

  get sendQuotesAsInvoice(): boolean {
    return this.object?.sendQuotesAsInvoice || false
  }

  get defaultSendDriverInfoEmail(): boolean {
    return this.object?.notificationsSetting?.defaultSendDriverInfoEmail ?? true
  }

  get defaultSendDriverOnTheWayEmail(): boolean {
    return (
      this.object?.notificationsSetting?.defaultSendDriverOnTheWayEmail ?? true
    )
  }

  updateSendQuotesAsInvoice(sendAsInvoice: boolean): void {
    EventBus.$emit('update:send-quotes-as-invoice', sendAsInvoice)
    this.awaitingObjectUpdate = true
  }

  updateSendDriverInfoEmail(defaultSendDriverInfoEmail: boolean): void {
    EventBus.$emit('update:notifications', {
      defaultSendDriverOnTheWayEmail: this.defaultSendDriverOnTheWayEmail,
      defaultSendDriverInfoEmail,
    })
    this.awaitingObjectUpdate = true
  }

  updateSendDriverOnTheWayEmail(defaultSendDriverOnTheWayEmail: boolean): void {
    EventBus.$emit('update:notifications', {
      defaultSendDriverOnTheWayEmail,
      defaultSendDriverInfoEmail: this.defaultSendDriverInfoEmail,
    })
    this.awaitingObjectUpdate = true
  }
}
