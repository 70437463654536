
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import graph from '@/assets/images/graph.png'
import { TimeSeriesResult } from '@/models/dto/Time'
import { currencyFilterHideCents, percentageFormatter } from '@/utils/string'
import LineChart from './LineChart.vue'

@Component({ components: { LineChart } })
export default class DetailsStatsCard extends Vue {
  @Prop({ required: true }) readonly quotesData!: TimeSeriesResult
  @Prop({ required: true }) readonly reservationsData!: TimeSeriesResult
  @Prop({ required: true }) readonly startDate!: string
  @Prop({ required: true }) readonly endDate!: string
  graph = graph
  LineChart = LineChart
  currencyFilterHideCents = currencyFilterHideCents
  percentageFormatter = percentageFormatter

  @Watch('startDate', { immediate: true })
  @Watch('endDate', { immediate: true })
  handleDateChange(): void {
    this.datePickerValue = [this.startDate, this.endDate]
  }

  datePickerValue = []

  get quoteValueData(): Record<string, unknown> {
    return {
      labels: this.quotesData?.timeSeries?.map((data) => data.date),
      datasets: [
        {
          label: 'amount',
          data: this.quotesData?.timeSeries?.map((data) => data.amount),
          fill: true,
          backgroundColor: '#ECFDF3',
          borderColor: '#069E8A',
          tension: 0.33,
        },
      ],
    }
  }

  get reservationValueData(): Record<string, unknown> {
    return {
      labels: this.reservationsData?.timeSeries?.map((data) => data.date),
      datasets: [
        {
          label: 'amount',
          data: this.reservationsData?.timeSeries?.map((data) => data.amount),
          fill: true,
          backgroundColor: '#ECFDF3',
          borderColor: '#069E8A',
          tension: 0.33,
        },
      ],
    }
  }

  get chartOptions(): Record<string, unknown> {
    return {
      scales: {
        yAxis: {
          display: false,
        },
        xAxis: {
          display: false,
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
        },
      },
      elements: {
        point: {
          radius: 0,
        },
        line: {
          borderWidth: 1,
        },
      },
      responsive: true,
      maintainAspectRatio: false,
    }
  }

  get conversionPercentage(): string {
    if (!this.quotesData?.totalCount) {
      return '--'
    }
    return percentageFormatter(
      (this.quotesData.totalConvertedCount / this.quotesData.totalCount) * 100,
      0
    )
  }
}
