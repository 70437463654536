
import { Vue, Component, Prop } from 'vue-property-decorator'
import { DataTableColumn } from '@/models/DataTableColumn'
import customers from '@/services/customer'
import CUCollectionTable from '@/components/CUCollectionTable.vue'
import { TableAction } from '@/models/TableAction'
import sidebar from '@/store/modules/sidebar'
import { EventBus } from '@/utils/eventBus'
import { filter } from '@/utils/filter'
import CUDataTableLink from './CUDataTableLink.vue'
import { RawLocation } from 'vue-router'
import ContactSidebarDetail from './ContactSidebarDetail.vue'
import { Customer } from '@/models/dto/Customer'
import { formatFullName, phoneFormatFilter } from '@/utils/string'
import { NAME_COLUMN_WIDTH } from '@/data/tableViews'
import { TableViewParameters, TableViewResult } from '@/models/TableView'
import { AxiosResponse } from 'axios'

@Component({
  components: { CUCollectionTable },
})
export default class CompanyContacts extends Vue {
  mounted(): void {
    EventBus.$on('contacts:contact-selected', this.handleContactSelected)
  }

  beforeDestroy(): void {
    EventBus.$off('contacts:contact-selected', this.handleContactSelected)
  }

  @Prop({ required: false }) readonly companyId!: string

  filteredTableView(
    params: TableViewParameters
  ): Promise<AxiosResponse<TableViewResult<Customer>>> {
    const filters = filter()
    const parentFilter = filters.createParent('and')
    filters.add(parentFilter, {
      column: {
        _t_id: '35f6272b-a24e-49b9-bb02-3f2dc0bfbd8d',
        value: 'customerAccountId',
        filterAsIs: true,
        filterType: 'eq',
      },
      value: this.companyId,
    })
    params = { ...params, filters: filters.asQueryParams() }
    return customers.tableView(params)
  }

  count = 0

  handleUpdateCount(count: number): void {
    this.count = count
  }

  handleContactSelected(customer: Customer): void {
    if (customer.customerId) {
      sidebar.push({
        component: ContactSidebarDetail,
        props: { userId: customer.customerId },
        fab: true,
      })
    }
  }

  actions: TableAction[] = []

  columns: DataTableColumn[] = [
    {
      _t_id: 'e9ae5d2f-b37e-4039-9930-4eee78ac0729',
      text: 'Name',
      value: '',
      columnSlotComponent: CUDataTableLink,
      clickEvent: 'contacts:contact-selected',
      computedText: (row: Customer): string => formatFullName(row),
      linkLocation: (row: Customer): RawLocation => ({
        name: 'contacts.detail',
        params: { id: String(row.customerId) },
      }),
      width: NAME_COLUMN_WIDTH,
    },
    {
      _t_id: '60788ecd-0b9f-4477-b89a-b89e09821c65',
      text: 'Email',
      value: 'email',
      width: 120,
    },
    {
      _t_id: '98032ece-7415-44a1-b8e0-0715726711ff',
      text: 'Phone',
      value: 'phone',
      computedText: (row): string => phoneFormatFilter(row.phone),
      width: 120,
    },
  ]
}
