
import { CustomerBalanceSummary } from '@/models/dto/Customer'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { currencyFilterHideCents } from '@/utils/string'

@Component({})
export default class DetailsDueCard extends Vue {
  @Prop({ required: true }) readonly balanceSummary!: CustomerBalanceSummary

  currencyFilterHideCents = currencyFilterHideCents
}
