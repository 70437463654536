
import { Prop } from 'vue-property-decorator'
import Component, { mixins } from 'vue-class-component'
import DateMixin from '@/mixins/DateMixin'
import { DataTableColumn } from '@/models/DataTableColumn'
import reservations from '@/services/reservation'
import { currencyFilter, toTitle } from '@/utils/string'
import CUCollectionTable from '@/components/CUCollectionTable.vue'
import { TableAction } from '@/models/TableAction'
import sidebar from '@/store/modules/sidebar'
import { EventBus } from '@/utils/eventBus'
import { Reservation } from '@/models/dto/Reservation'
import { RawLocation } from 'vue-router'
import CUDataTableLink from '@/components/CUDataTableLink.vue'
import ReservationsListTotalDue from '@/components/ReservationsListTotalDue.vue'
import { filter } from '@/utils/filter'
import { v4 } from 'uuid'
import UserSidebarDetail from './UserSidebarDetail.vue'
import ReservationsListDriverAssignments from '@/components/ReservationsListDriverAssignments.vue'
import ReservationsListVehicleAssignments from '@/components/ReservationsListVehicleAssignments.vue'
import {
  CURRENCY_COLUMN_WIDTH,
  DATE_COLUMN_WIDTH,
  NAME_COLUMN_WIDTH,
} from '@/data/tableViews'
import { TableViewParameters, TableViewResult } from '@/models/TableView'
import { AxiosResponse } from 'axios'

@Component({
  components: { CUCollectionTable },
})
export default class CompanyReservations extends mixins(DateMixin) {
  mounted(): void {
    EventBus.$on('reservations:open-booked-by', this.openBookedBy)
  }

  beforeDestroy(): void {
    EventBus.$off('reservations:open-booked-by', this.openBookedBy)
  }

  @Prop({ required: false }) readonly customerAccountName!: string

  filteredTableView(
    params: TableViewParameters
  ): Promise<AxiosResponse<TableViewResult<Reservation>>> {
    const filters = filter()
    const parentFilter = filters.createParent('and')
    filters.add(parentFilter, {
      column: {
        _t_id: v4(),
        value: 'customerAccountName',
        filterAsIs: true,
        filterType: 'eq',
      },
      value: this.customerAccountName,
    })
    params = { ...params, filters: filters.asQueryParams() }
    return reservations.tableView(params)
  }

  count = 0

  openBookedBy(row: Reservation): void {
    sidebar.push({
      component: UserSidebarDetail,
      props: {
        // firstName: row.bookedBy.firstName, TODO:uncomment these once bookedby is surfaced
        // lastName: row.bookedBy.lastName,
        // email: row.bookedBy.email,
      },
      title: 'Booked By',
      fab: true,
    })
  }

  actions: TableAction[] = []

  columns: DataTableColumn[] = [
    {
      _t_id: '89bed05c-7012-48dc-af85-1d6ca5579183',
      text: 'Res. ID',
      value: 'managedId',
      type: 'slot',
      columnSlotComponent: CUDataTableLink,
      linkLocation: (row: Reservation): RawLocation => ({
        name: 'reservations.detail',
        params: { id: String(row.reservationId) },
      }),
      hidden: false,
      width: 110,
    },
    {
      _t_id: '021b9c1c-7eff-4eb4-a5ed-f07252a182a4',
      text: 'Res. Status',
      value: 'reservationStatus',
      computedText: (row): string => toTitle(row.reservationStatus),
      hidden: false,
      width: 120,
    },
    {
      _t_id: '1cadb660-9a4b-4b00-94dd-0e78e7945cce',
      text: 'Pickup Date',
      value: 'pickupDate',
      computedText: (row): string =>
        this.formatLongDateLongTime(row.pickupDate, {
          showTimezone: true,
          showMeridianUpper: true,
          showDot: true,
          tz: row.firstPickupAddressTimezone,
        }) || '',
      hidden: false,
      width: DATE_COLUMN_WIDTH,
    },
    {
      _t_id: 'a79bffdb-d663-44a6-8bb6-8f0b355ab45d',
      text: 'Total Due',
      value: 'amount',
      columnSlotComponent: ReservationsListTotalDue,
      hidden: false,
      width: CURRENCY_COLUMN_WIDTH,
    },
    {
      _t_id: '3d6aac76-52c1-473e-804f-730f885d379a', // need to surface due date to support highlighting when overdue
      text: 'Balance',
      value: 'balance',
      computedText: (row): string => currencyFilter(row.balance),
      hidden: false,
      width: CURRENCY_COLUMN_WIDTH,
    },
    {
      _t_id: 'e76b5170-ed96-4225-979d-b54457b3a224',
      text: 'Driver',
      value: 'vehicleAssignments/driverAssignments',
      type: 'slot',
      columnSlotComponent: ReservationsListDriverAssignments,
      hidden: false,
      width: NAME_COLUMN_WIDTH,
    },
    {
      _t_id: '1fc0b0e0-c87f-4875-839f-cccf9fb491ca',
      text: 'Vehicle',
      value: 'vehicleAssignments',
      type: 'slot',
      columnSlotComponent: ReservationsListVehicleAssignments,
      hidden: false,
      width: NAME_COLUMN_WIDTH,
    },
    {
      _t_id: 'c2d11372-cd4e-464f-957d-0f60789055f4',
      text: 'Last Activity',
      value: 'createdOn', //needs to be changed to updatedOn once that's surfaced
      computedText: (row): string => this.formatMediumDate(row.createdOn),
      hidden: false,
      width: DATE_COLUMN_WIDTH,
    },
  ]
}
